import cn from "classnames";
import { Container } from "components/layout/Container";
import styles from "./NotFound.module.scss";

type NotFoundProps = {
  query: string;
  className?: string;
  contentClassName?: string;
  hideMd?: boolean;
};

export const NotFound = ({ query, className, hideMd, contentClassName }: NotFoundProps) => {
  return (
    <div
      className={cn(
        styles.default,
        {
          [styles.hideMd]: hideMd,
        },
        className
      )}
    >
      <Container>
        <div className={cn(styles.wrapper, contentClassName)}>
          Sorry, we couldn&apos;t find any results for <strong>{query}</strong>.<br />
          Please try a different query instead.
        </div>
      </Container>
    </div>
  );
};
