"use client";
import { SectionToolsPromo } from "@/components/pages/tools/SectionToolsPromo";
import { SectionToolsMain } from "@/components/pages/tools/SectionToolsMain";
import {
  SectionToolsBenefits,
  SectionToolsBenefitsProps,
} from "@/components/pages/tools/SectionToolsBenefits";
import { SectionCalculator } from "@/components/shared/sections/SectionCalculator";
import React, { useEffect, useState } from "react";
import { StrapiMetaData } from "@/types/strapi/components/shared/meta";
import { SVGSpriteIconType } from "@/components/UI/SVGSpriteIconType";

type ToolsPageProps = {
  content: {
    meta: StrapiMetaData;
    sectionPromo: {
      heading: string;
      subheading: string;
    };
    sectionBenefits: SectionToolsBenefitsProps;
    calculatorContent: {
      heading: string;
      subheading: string;
    };
  };
  tools: {
    id: number;
    heading: string;
    href: string;
    tool: any;
    icon: SVGSpriteIconType;
    text: string;
    buttonText: string;
  }[];
};

export const ToolsPage = ({ content, tools }: ToolsPageProps) => {
  const { sectionPromo, calculatorContent, sectionBenefits } = content;
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [toolsList, setToolsList] = useState(tools);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    const term = searchTerm?.trim()?.toLowerCase();

    if (!term) {
      setToolsList(tools);
      return;
    }

    setToolsList(
      tools.filter(({ heading, text, buttonText }) => {
        return (
          heading.toLowerCase().indexOf(term) > -1 ||
          text.toLowerCase().indexOf(term) > -1 ||
          buttonText.toLowerCase().indexOf(term) > -1
        );
      })
    );
  }, [searchTerm, tools]);

  return (
    <>
      <SectionToolsPromo {...sectionPromo} searchTerm={searchTerm} handleSearch={handleSearch} />
      <SectionToolsMain tools={toolsList} searchTerm={searchTerm} />
      <SectionToolsBenefits {...sectionBenefits} />
      <SectionCalculator {...calculatorContent} />
    </>
  );
};
