import { Container } from "components/layout/Container";
import { Heading } from "components/layout/Heading";
import { PriceCalculator } from "components/shared/PriceCalculator";
import s from "./SectionCalculator.module.scss";
import Image from "next/image";

export type SectionCalculatorProps = {
  heading: string;
  subheading?: string;
};

export const SectionCalculator = ({ heading, subheading }: SectionCalculatorProps) => {
  return (
    <section className={s.section}>
      <div className={s.bg}>
        <Image src="/img/section-order-grad.jpg" width={2540} height={1130} alt="Background" />
      </div>
      <Container className={s.container}>
        <Heading title={heading} subheading={subheading} className={s.heading} />
        <div className={s.calcWrapper}>
          <PriceCalculator className={s.calculator} withPremiumFeatures />
        </div>
      </Container>
    </section>
  );
};
