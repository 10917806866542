import { InputSearch } from "components/form/InputSearch";
import { SectionPromo } from "components/shared/sections/SectionPromo";
import type React from "react";
import s from "./SectionToolsPromo.module.scss";

type SectionToolsPromoProps = {
  heading: string;
  subheading: string;
  searchTerm: string;
  handleSearch: (value: string) => void;
};

export const SectionToolsPromo = ({ handleSearch, searchTerm, ...props }: SectionToolsPromoProps) => {
  return (
    <SectionPromo
      {...props}
      type="center"
      image={{
        src: "/img/pages/tools/tools.png",
        width: 110,
        height: 110,
      }}
      breadcrumbs={[
        { position: 1, name: "Main", item: "/" },
        { position: 2, name: "Tools", item: "/tools" },
      ]}
      promoWide
      className={s.section}
    >
      <InputSearch
        className={s.input}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        placeholder="Filter tools"
        type="secondary"
      />
    </SectionPromo>
  );
};
