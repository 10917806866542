import { Container } from "components/layout/Container";
import { Title } from "components/typography/Title";
import type { SVGSpriteIconType } from "@/components/UI/SVGSpriteIconType";
import s from "./SectionToolsBenefits.module.scss";
import { IconImageType } from "@/types/icon";
import { WrappedIcon } from "@/components/UI/WrappedIcon";
import cs from "@/styles/modules/card.module.scss";
import cn from "classnames";

export type SectionToolsBenefitsProps = {
  heading: string;
  items: {
    icon: SVGSpriteIconType | IconImageType;
    heading: string;
    text: string;
  }[];
};

export const SectionToolsBenefits = ({ heading, items }: SectionToolsBenefitsProps) => (
  <section className={s.section}>
    <Container>
      <Title level={2} className={s.heading}>
        {heading}
      </Title>
      <div className={s.cardsWrap}>
        <ul className={s.cardsWrapInner}>
          {items.map(({ icon, heading, text }) => (
            <li key={icon} className={cn(cs.cardGlassHover, s.card)}>
              <div className={s.cardTop}>
                <WrappedIcon icon={icon} />
                <strong className={cs.h4}>{heading}</strong>
              </div>
              <p>{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  </section>
);
