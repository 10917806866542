import { Input, InputProps } from "components/form/Input";
import s from "./InputSearch.module.scss";
import React, { useCallback, useMemo } from "react";
import cn from "classnames";
import { upperFirst } from "lodash";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import searchIcon from "public/img/icons/svg/search-alt.svg";
import closeIcon from "public/img/icons/svg/times.svg";
import { IconImage } from "@/components/UI/IconImage";

type SearchInputProps = Omit<InputProps, "type"> & {
  searchTerm: string;
  handleSearch: (value: string) => void;
  type?: "primary" | "secondary";
};

export const InputSearch = ({
  className,
  handleSearch,
  searchTerm,
  type = "primary",
  ...props
}: SearchInputProps) => {
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(event.target.value);
  };

  const onClear = useCallback(() => {
    handleSearch("");
  }, [handleSearch]);

  const hasValue = useMemo(() => !!searchTerm?.trim()?.length, [searchTerm]);

  const hasSearchIcon = useMemo(
    () => type !== "secondary" || (type === "secondary" && !hasValue),
    [type, hasValue]
  );

  const renderSearchIcon = useMemo(() => {
    return (
      hasSearchIcon &&
      (type === "primary" ? (
        <SpriteIcon icon={searchIcon} className={s.searchIcon} />
      ) : (
        <IconImage size={24} icon="magnifying-glass" className={s.searchIcon} />
      ))
    );
  }, [hasSearchIcon, type]);

  const renderCloseIcon = useMemo(() => {
    return (
      hasValue &&
      (type === "primary" ? (
        <SpriteIcon icon={closeIcon} className={s.clearIcon} onClick={onClear} />
      ) : (
        <IconImage size={24} icon="cross" className={s.clearIcon} onClick={onClear} />
      ))
    );
  }, [hasValue, onClear, type]);

  return (
    <label htmlFor="search" className={cn(s.label, s[`type${upperFirst(type)}`], className)}>
      {renderSearchIcon}
      <Input type="search" className={s.input} onChange={onInputChange} value={searchTerm} {...props} />
      {renderCloseIcon}
    </label>
  );
};
