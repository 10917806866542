import { Container } from "components/layout/Container";
import { DefaultCard } from "components/shared/cards/DefaultCard";
import { Button } from "components/UI/Buttons/Button";
import type { SVGSpriteIconType } from "@/components/UI/SVGSpriteIconType";
import s from "./SectionToolsMain.module.scss";
import sc from "@/styles/modules/card.module.scss";
import { NotFound } from "@/components/shared/NotFound";
import React from "react";
import cn from "classnames";
import { WrappedIcon } from "@/components/UI/WrappedIcon";
import { SLUG_IMG_MAP } from "@/helpers/menuIcons";
import { StrapiImageFile } from "@/types/strapi/file";

export type ToolPreviewType = {
  id: number;
  icon: SVGSpriteIconType;
  heading: string;
  imageIcon?: StrapiImageFile;
  tool: SVGSpriteIconType;
  text: string;
  buttonText: string;
  href: string;
};

type SectionToolsMainProps = {
  tools: ToolPreviewType[];
  searchTerm?: string;
};

export const SectionToolsMain = ({ tools, searchTerm }: SectionToolsMainProps) =>
  tools.length ? (
    <section className={s.section}>
      <Container>
        <ul className={s.inner}>
          {tools.map(({ icon, imageIcon, heading, text, buttonText, href, tool }) => (
            <li className={cn(sc.cardGlassHover, s.card)} key={href}>
              <div className={s.cardTop}>
                <WrappedIcon icon={imageIcon?.url || icon || SLUG_IMG_MAP[href.trim()]} />
                <strong>{heading}</strong>
              </div>

              <p>{text}</p>
              <Button href={href} type="white" className={s.btn} size="fullwidth" outline>
                {buttonText}
              </Button>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  ) : (
    <NotFound query={String(searchTerm)} className={s.notFound} />
  );
