import { forwardRef } from "react";
import cn from "classnames";
import type { SVGSpriteIconType } from "@/components/UI/SVGSpriteIconType";
import { upperFirst } from "lodash";
import type { InputHTMLAttributes } from "react";
import styles from "./Input.module.scss";

export type InputProps = {
  label?: string;
  valid?: boolean;
  icon?: SVGSpriteIconType;
  wrapperClassname?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ valid = true, icon, className, label, wrapperClassname, ...props }, ref) => {
    return (
      <div
        className={cn(
          styles.wrapper,
          styles[`type${upperFirst(props.type)}`],
          {
            [styles.withIcon]: !!icon,
          },
          wrapperClassname
        )}
      >
        {label && <span className={cn(styles.inputLabel, "inputLabel")}>{label}</span>}
        <div className={styles.inputWrap}>
          <input
            ref={ref}
            className={cn(styles.input, className, {
              [styles.invalid]: !valid,
            })}
            {...props}
          />
          {/*{icon && <Icon icon={icon} className={styles.icon} />}*/}
        </div>
      </div>
    );
  }
);

Input.displayName = "Input";
